import { on } from './modules/_fn';

(function () {
	// ---------------------------------------------------------------
	// 點獸醫師，全台服務 : Veterinary
	let swiperVeterinary = null;
	function buildVeterinaryCarousel() {
		const target = '#swiper-veterinary';
		swiperVeterinary = new Swiper(target, {
			lazy: true,
			slidesPerView: 1,
			spaceBetween: 5,
			speed: 400,
			watchSlidesProgress: true,
			pagination: {
				el: target + " .swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: target + ' .swiper-button-next',
				prevEl: target + ' .swiper-button-prev',
			},
			breakpoints: {
				// when window width is >= 576px
				768: {
					slidesPerView: 2,
					spaceBetween: 5
				},
				// when window width is >= 1200px
				1200: {
					slidesPerView: 3,
					spaceBetween: 5
				},
			}
		});
	}

	on(window, 'load', buildVeterinaryCarousel);
	// ---------------------------------------------------------------

	// ---------------------------------------------------------------
	// 預約方式 : Reserve
	let swiperReserve = null;
	function buildReserveCarousel() {
		const target = '#swiper-reserve';
		swiperReserve = new Swiper(target, {
			lazy: true,
			slidesPerView: 1,
			speed: 400,
			watchSlidesProgress: true,
			pagination: {
				el: target + " .swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: target + ' .swiper-button-next',
				prevEl: target + ' .swiper-button-prev',
			},
			breakpoints: {
				// when window width is >= 576px
				768: {
					slidesPerView: 2,
				},
				// when window width is >= 1200px
				1200: {
					slidesPerView: 3,
				},
			}
		});
	}

	on(window, 'load', buildReserveCarousel);
	// ---------------------------------------------------------------

	// ---------------------------------------------------------------
	// 享家好文，帶您一同閱讀 : Partner
	let swiperPartner = null;
	function buildPartnerCarousel() {
		swiperPartner = new Swiper('#swiper-partner', {
			slidesPerView: 'auto',
			spaceBetween: 25,
			speed: 400,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				// when window width is >= 1200px
				768: {
					slidesPerView: 4,
					spaceBetween: 40,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 80,
				},
			},
			on: function () {
				init: () => { swiperPartner.update() }
			}
		});
	}

	on(window, 'load', buildPartnerCarousel);
	// ---------------------------------------------------------------
})();
